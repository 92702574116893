import { GetServerSideProps } from 'next';
import SignInPageBody from './signin';
import AuthLayout from '../../../../components/layouts/AuthLayout';
import { handleAndGetTenantAsync, PageBaseProps } from '../../../../utils/tenants';

type Props = PageBaseProps & {

}

export default function SignInPage(props: Props) {

    return <AuthLayout>
        <SignInPageBody />
    </AuthLayout>
}


export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
    const { tenant, auth } = await handleAndGetTenantAsync(ctx);

    if (!auth.isFromAllowedIP) {
        return {
            notFound: true
        }
    }

    if (!auth.isAnyMethodEnabled) {
        const baseDestination = `/${tenant.currentLocale}/auth/not-configured`;
        let destinationUrl = baseDestination;
      
        if (ctx.query.return) {
          const returnValue = Array.isArray(ctx.query.return)
            ? ctx.query.return[0]
            : ctx.query.return;
      
      
          const returnUrlParam = returnValue?.startsWith('?')
            ? returnValue
            : `?return=${returnValue}`;
      
          destinationUrl += returnUrlParam;
        }
        return {
            redirect: {
                permanent: false,
               destination: destinationUrl,
            },
            props: {},
        };
    }

    if (auth.isAuthenticated) {
        return {
            redirect: {
                permanent: false,
                destination: "/",
            },
            props: {},
        };
    }

    return {
        props: {
            tenant,
            auth
        }
    }
}